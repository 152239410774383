import { ref, Ref } from 'vue';
import { ListHeaderItem, RequestData } from '@/components/common/list';
import HttpRequest from '@/util/axios.config';
import { isCommunity } from '@/methods/role';

interface PerDeviceType {
    ID: string;
    MAC: string;
}

interface InitDataType {
    Model: string;
    UpdateTime: Date;
    Device: Array<string>;
    Version: string;
    ID: string;
}

const statusOptions = [{
    label: WordList.ProperAllTextPending,
    value: '0'
}, {
    label: WordList.ProperAllTextProcessing,
    value: '1'
}, {
    label: WordList.ProperAllTextExecuted,
    value: '2'
}];

const headers: Array<ListHeaderItem> = [{
    label: WordList.TabelUpdateBoxVersion,
    name: 'Version'
}, {
    label: WordList.TabelUpdateBoxDevice,
    name: 'DeviceList'
}, {
    label: WordList.MulListUpdateListTanleStatus,
    name: 'Status',
    type: 'customize'
}, {
    label: WordList.UpdateListTanleUpdateTime,
    name: 'UpdateTime'
}, {
    label: WordList.TabelMessageBoxCreateTime,
    name: 'CreateTime'
}];

const listRequestData: Ref<RequestData> = ref({
    url: 'getupdatedevice',
    param: {
        Status: '',
        Version: ''
    }
});

// 获取所有设备, 为之后编辑操作判断对应id勾选
const getDevice = (callback: Function) => {
    // `v3/web/${isCommunity() ? 'community' : 'office'}/device/getAllForUpgrade`
    HttpRequest.get('v3/web/single/device/getAllForUpgrade', {}, callback);
};
function getPersonalDevice() {
    const personalDevice: Ref<{
        [key in string]: string;
    }> = ref({});
    getDevice((res: {
        data: Array<PerDeviceType>;
    }) => {
        res.data.forEach((item: PerDeviceType) => {
            personalDevice.value[item.MAC] = item.ID;
        });
    });
    return personalDevice;
}

export default null;
export {
    PerDeviceType,
    statusOptions,
    headers,
    InitDataType,
    getPersonalDevice,
    listRequestData
};
