
import {
    defineComponent, onMounted, Ref, ref
} from 'vue';
import {
    listPagination,
    ListActionItem
} from '@/components/common/list';
import addButton from '@/components/common/add-button/index.vue';
import updateDialog from '@/components/view/installer/community/update-dialog';
import {
    statusOptions,
    headers,
    InitDataType,
    getPersonalDevice,
    listRequestData
} from './util';

export default defineComponent({
    components: {
        listPagination,
        addButton,
        updateDialog
    },
    setup() {
        const updateToList = ref(0);
        const updateType = ref('add');

        const isShowUpdateDialog = ref(false);

        const initData = ref({
            Model: '',
            UpdateTime: new Date(),
            Device: [''],
            Version: '',
            ID: ''
        });

        // 获取所有设备, 为之后编辑操作判断对应id勾选
        const personalDevice = getPersonalDevice();
        const initDeviceID: Ref<Array<string>> = ref([]);

        const add = () => {
            isShowUpdateDialog.value = true;
            updateType.value = 'add';
            // 新增计划重置变量，避免弹窗值复用
            initData.value = {
                Model: '',
                UpdateTime: new Date(),
                Device: [''],
                Version: '',
                ID: ''
            };
            initDeviceID.value = [];
        };

        const action: ListActionItem[] = [{
            type: 'modify',
            event(val: InitDataType) {
                updateType.value = 'modify';
                isShowUpdateDialog.value = true;
                initData.value = val;
                // 初始化勾选设备
                initDeviceID.value = [];
                val.Device.forEach((mac) => {
                    if (personalDevice.value[mac]) {
                        initDeviceID.value.push(personalDevice.value[mac]);
                    }
                });
            },
            class: 'el-icon-my-modify modify-icon',
            showCondition(val: { Status: string }) {
                return val.Status === '0';
            },
            title: WordList.TabelConfigInHtmlModify
        }];

        const success = () => {
            isShowUpdateDialog.value = false;
            updateToList.value += 1;
        };

        return {
            listRequestData,
            statusOptions,
            updateToList,
            headers,
            add,
            action,
            isShowUpdateDialog,
            success,
            updateType,
            initData,
            initDeviceID
        };
    }
});
